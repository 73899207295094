
































































































































































































































































































































































































.deleted-row {
    td {
        background-color: #ffdada !important;
    }

    &.el-table__row--striped td {
        background-color: #f9a2a2 !important;
    }
}
.suspended-row {
    td {
        background-color: #fddbb1 !important;
    }

    &.el-table__row--striped td {
        background-color: #ffd197 !important;
    }
}
